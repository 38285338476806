<template>
  <div class="authorize">
    <Popup v-model="isShow" closeable :close-on-click-overlay="false">
      <div id="pdf"></div>
      <Button type="primary" hairline @click="downloadPdf">下载</Button>
    </Popup>
  </div>
</template>

<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import { Button, Popup } from "vant";
export default {
  components: { Button, Popup },
  props: {
    pdfUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShow: false,
      pdfh5: null,
    };
  },
  methods: {
    show() {
      this.isShow = true;
      this.$nextTick(() => {
        this.pdfh5 = new Pdfh5("#pdf", {
          pdfurl: this.pdfUrl,
          goto: 1,
        });
        // 监听pdf准备开始渲染，此时可以拿到pdf总页数
        this.pdfh5.on("ready", function () {
          console.log("总页数:" + this.totalNum);
        });
      });
    },
    // downloadPdf() {
    //   fetch(this.pdfUrl)
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       const e = new Blob([blobRes], {
    //         type: "application/octet-stream",
    //         "Content-Disposition": "attachment",
    //       });
    //       // 将 Blob 对象转为 url
    //       const link = window.URL.createObjectURL(e);
    //       const url = window.URL.createObjectURL(
    //         new Blob([blob], { type: "application/pdf" })
    //       );
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute(
    //         "download",
    //         url.substring(url.lastIndexOf("/") + 1)
    //       );
    //       document.body.appendChild(link);
    //       link.click();
    //       link.parentNode.removeChild(link);
    //     });
    // },
    downloadPdf() {
      fetch(this.pdfUrl, {
        method: "get",
        responseType: "arraybuffer",
      })
        .then((res) => {
          if (res.status !== 200) {
            return res.json();
          }
          return res.arrayBuffer();
        })
        .then((blobRes) => {
          const e = new Blob([blobRes], {
            type: "application/octet-stream",
            "Content-Disposition": "attachment",
          });
          const url = window.URL.createObjectURL(e);
          const a = document.createElement("a");
          a.href = this.pdfUrl;
          arguments.download = url.substring(url.lastIndexOf("/") + 1);
          document.body.appendChild(a);
          window.open(this.pdfUrl);
          a.click();
          a.parentNode.removeChild(a);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.authorize {
  /deep/.van-popup {
    width: 100%;
    height: 80vh;
  }
  #pdf {
    height: calc(80vh - 50px);
  }
  button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    margin: auto;
  }
}
</style>
